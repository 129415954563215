$(window).on('load', function() {
    $select2.on("change", function (e) {
        var cidade = $('#cidade').val()
            produto = '',
            params = {},
            $form = $(e.target).closest('form'),
            action = $form.attr('action');
        if ($("#produto").length > 0) {
            produto = $('#produto').val()
        }

        if ($.trim(cidade) !== ''
            || $.trim(produto) !== '') {
            params = {cidade:cidade, produto:produto}
        }

        window.showLoading();
        $.getJSON(action, params, function (data) {
            window.hideLoading();

            if (data.resultado === "0") {
                var myModalEl = document.getElementById('alertModal');
                var myModal = new bootstrap.Modal(myModalEl, {
                    // keyboard: false
                });
                myModalEl.addEventListener('show.bs.modal', function (event) {
                    // var modalTitle = modalDialog.querySelector('.modal-title');
                    var modalBody = myModalEl.querySelector('.modal-body');
                    // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                    // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                    modalBody.innerHTML = data.resultado_txt;
                });
                myModal.show();
                window.initMapMarkers([]);

                $('#cidade').val(null).trigger('change');
            }else{
                var $itens = data.itens,
                    $cidades = data.cidades;

                locations = [],
                myLatLng = {lat: -8.0433053, lng: -35.0042572};
                $itens.forEach(function($cli, i) {
                    var address = $cli.address + "<br>" + $cli.bairro + " - " + $cli.cidade + "/" +  $cli.uf + "<br><span class='fw-bolder'>CEP:</span>"  + $cli.cep;
                    var addressLink = $cli.address + " - " + $cli.bairro + ", " + $cli.cidade + " - " +  $cli.uf + ", "  + $cli.cep;
                    locations.push(["<h3 class='fs-6'>" + $cli.nome_fantasia + "</h3>"+address+"<br><br><a href='https://www.google.com.br/maps/dir//"+addressLink+"' target='_blank' class='link-info text-decoration-none'><i class='fas fa-route'></i> Como chegar?</a>", $cli.latitude, $cli.longitude, i]);

                    if (i == 0
                        && (typeof params.cidade !== 'undefined' || typeof params.produto !== 'undefined')) {
                        myLatLng = {lat: parseFloat($cli.latitude), lng: parseFloat($cli.longitude)};
                        document.getElementById('zoom').value = 5;
                        if (typeof params.cidade !== 'undefined' && $.trim(params.cidade) !== '')
                            document.getElementById('zoom').value = 12;
                    }
                });

                if ($(e.target).attr('id') == 'produto') {
                    // var select = $('#cidade');
                    // select.find('option').remove();

                    // var option = new Option('', '', false, true);
                    // select.append(option);
                    // for (var d in $cidades) {
                    //     // options[d] = new Option($cidades[d].cidades, $cidades[d].cidades);
                    //     if (typeof params.cidade !== 'undefined'
                    //         && $.trim(params.cidade) !== ''
                    //         && $.trim(params.cidade) === $cidades[d].id
                    //         ) {
                    //         var option = new Option($cidades[d].text, $cidades[d].id, true, true);
                    //     }else{
                    //         var option = new Option($cidades[d].text, $cidades[d].id, false, false);
                    //     }
                    //     select.append(option);
                    // }
                    // // console.log(options);

                    // // manually trigger the `select2:select` event
                    // select.trigger({
                    //     type: 'select2:select',
                    //     params: {
                    //         data: $cidades
                    //     }
                    // });

                    var $cep = $('#cep');
                    if ($cep.val().length == 9) {
                        searchAddress($cep.val());
                    }
                }

                if (typeof params.cidade === 'undefined'
                    && typeof params.produto === 'undefined') {
                    document.getElementById('zoom').value = 7;
                }

                if ($("#map").length > 0
                    && typeof locations !== 'undefined'
                    && typeof myLatLng !== 'undefined'
                    && locations.length > 0) {
                    window.initMapMarkers(locations);
                }
                // console.log(data);;
            }
        });
	});

    window.cepold = '';
    $('#cep').on("keyup", function (e) {
        var $cep = $(this);

        if ($cep.val().length == 9
            && $cep.val() !== window.cepold) {
            window.cepold = $cep.val();
            // console.log($cep.val());

            showLoading();
            searchAddress($cep.val());
            hideLoading();
        }
    });

});
