window.hideLoading = function() {
    $('#loading').hide();
    $('body').removeClass('overflow_hidden');;
}
window.showLoading = function() {
    $('#loading').show();
    $('body').addClass('overflow_hidden');;
}

function getLatLng(address) {
    showLoading();
    // console.log(address);

    $.getJSON(URL_BASE+"getLatLng", {address:address} , function (data) {
        hideLoading();

        if (data.resultado === "0") {
            var myModalEl = document.getElementById('alertModal');
            var myModal = new bootstrap.Modal(myModalEl, {
                // keyboard: false
            });
            myModalEl.addEventListener('show.bs.modal', function (event) {
                // var modalTitle = modalDialog.querySelector('.modal-title');
                var modalBody = myModalEl.querySelector('.modal-body');
                // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                modalBody.innerHTML = data.resultado_txt;
            });
            myModal.show();

            $("#latitude").val('');
            $("#longitude").val('');
            $("#address").val('');
        }else{
            $("#latitude").val(data.latitude);
            $("#longitude").val(data.longitude);
            $("#address").val(data.address);
        }
    });
}

$(window).on('load', function() {
    //Tabelas
    if ($('#dataTable').length > 0) {
        $('#dataTable').DataTable({
            // searching: false,
            // ordering:  false,
            // paging: false,
            "language": {
                "decimal": ",",
                "thousands": ".",
                "lengthMenu": "Visualizar _MENU_ itens por página",
                "zeroRecords": "Nenhum item encontrado",
                "info": "Página _PAGE_ de _PAGES_",
                "infoEmpty": "Nenhum item encontrado",
                "infoFiltered": "(filtrado de _MAX_ registros totais)",

                "emptyTable":     "Nenhum item encontrado",
                "loadingRecords": "Loading...",
                "processing":     "Processando...",
                "search":         "Buscar:",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                },
                "aria": {
                    "sortAscending":  ": Ordenar coluna por ordem crescente",
                    "sortDescending": ": Ordenar coluna por ordem descrescente"
                }
            }
        });
    }

    // Example starter JavaScript for disabling form submissions if there are invalid fields
    (function () {
        'use strict'

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
            })
    })()

    //Select2
    $select2 = $('.select2').select2({
        theme: 'bootstrap-5',
        language: "pt-BR",
        allowClear: true,
        selectionCssClass: 'form-select',
    });
    $select2.on("select2:open", function (e) {
		$(".select2-search__field").attr("placeholder", "Buscar");
		$("body .select2-search__field").focus();
	})

    $("body.parceiros_create").on("change", "#cnpj_cpf", function () {
        var $this = $(this),
            cnpj_cpf = $this.val();

		if ($this.is("input")
            && cnpj_cpf.length > 0) {
            showLoading();

            if (cnpj_cpf.length != 14) {
                hideLoading();
                var myModalEl = document.getElementById('alertModal');
                var myModal = new bootstrap.Modal(myModalEl, {
                    // keyboard: false
                });
                myModalEl.addEventListener('show.bs.modal', function (event) {
                    // var modalTitle = modalDialog.querySelector('.modal-title');
                    var modalBody = myModalEl.querySelector('.modal-body');
                    // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                    // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                    modalBody.innerHTML = 'O campo CNPJ deve conter apenas números e com um total de 14 dígitos.';
                });
                myModal.show();
            }else{
                $.getJSON(URL_BASE+"getInfoCnpj", {cnpj:cnpj_cpf} , function (data) {
                    hideLoading();

                    if (data.status !== 0) {
                        var myModalEl = document.getElementById('alertModal');
                        var myModal = new bootstrap.Modal(myModalEl, {
                            // keyboard: false
                        });
                        myModalEl.addEventListener('show.bs.modal', function (event) {
                            // var modalTitle = modalDialog.querySelector('.modal-title');
                            var modalBody = myModalEl.querySelector('.modal-body');
                            // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                            // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                            modalBody.innerHTML = data.msg;
                        });
                        myModal.show();
                    }else{
                        $nome_fantasia = ($.trim(data.titulo_estabelecimento) == "") ? data.nome_empresarial : data.titulo_estabelecimento;
                        $("#nome_fantasia").val($nome_fantasia);
                        $("#razao_social").val(data.nome_empresarial);
                        $("#cep").val(data.cep);
                        $("#endereco").val(data.logradouro);
                        $("#numero").val(data.numero);
                        $("#complemento").val(data.complemento);
                        $("#bairro").val(data.bairro);
                        $("#cidade").val(data.municipio);
                        $("#uf").val(data.uf);

                        var address = data.logradouro + ', ' + data.numero + ' - ' + data.bairro + ' - ' + data.municipio + ' - ' + data.uf;
                        getLatLng(address);
                    }
                });
            }
        }
    });

    $("body.parceiros_create").on("change", "#cep, #number", function () {
        var $this = $(this),
        cep = $('#cep').val();

		if ($this.is("input")
            && cep.length > 0) {
            showLoading();

            if (cep.length != 8) {
                hideLoading();
                var myModalEl = document.getElementById('alertModal');
                var myModal = new bootstrap.Modal(myModalEl, {
                    // keyboard: false
                });
                myModalEl.addEventListener('show.bs.modal', function (event) {
                    // var modalTitle = modalDialog.querySelector('.modal-title');
                    var modalBody = myModalEl.querySelector('.modal-body');
                    // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                    // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                    modalBody.innerHTML = 'O campo CEP deve conter apenas números e com um total de 8 dígitos.';
                });
                myModal.show();
            }else{
                $.getJSON(URL_BASE+"getInfoCep", {cep:cep} , function (data) {
                    hideLoading();

                    if (data.resultado === "0") {
                        var myModalEl = document.getElementById('alertModal');
                        var myModal = new bootstrap.Modal(myModalEl, {
                            // keyboard: false
                        });
                        myModalEl.addEventListener('show.bs.modal', function (event) {
                            // var modalTitle = modalDialog.querySelector('.modal-title');
                            var modalBody = myModalEl.querySelector('.modal-body');
                            // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                            // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                            modalBody.innerHTML = data.resultado_txt;
                        });
                        myModal.show();

                        $("#endereco").val('');
                        $("#bairro").val('');
                        $("#cidade").val('');
                        $("#uf").val('');
                    }else{
                        $("#endereco").val(data.tipo_logradouro + ' ' + data.logradouro);
                        $("#bairro").val(data.bairro);
                        $("#cidade").val(data.cidade);
                        $("#uf").val(data.uf);
                        $("#numero").focus();

                        if ($.trim($("#numero")) == '') {
                            var address = data.tipo_logradouro + ' ' + data.logradouro + ' - ' + data.bairro + ' - ' + data.cidade + ' - ' + data.uf;
                        }else{
                            var address = data.tipo_logradouro + ' ' + data.logradouro + ', ' + $("#numero").val() + ' - ' + data.bairro + ' - ' + data.cidade + ' - ' + data.uf;
                        }
                        getLatLng(address);
                    }
                });
            }
        }
    });

    hideLoading();
});
